import { Observable, firstValueFrom } from 'rxjs';
import { PdfSection, TableContent, TableRow } from '../entities/pdf-content';
import { Client } from '../entities/household';
import { HouseholdService } from './household.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JourneyClientService } from './journey-client.service';
import { JourneyService } from './journey.service';
import { PdfSectionTypes } from '../enums/pdf-section-type';
import { PrivacyQuestion } from '../entities/privacy-question';
import { PrivacyQuestions } from '../enums/privacy-question';
import { SessionTypes } from '../enums/session-type';
import { TextTemplateService } from './text-template.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PrivacyQuestionService {

  constructor(
    private http: HttpClient,
    private householdService: HouseholdService,
    private journeyClientService: JourneyClientService,
    private textTemplateService: TextTemplateService,
    private journeyService: JourneyService,
  ) { }


  readonly referenceQuestions: Question[] = [
    {
      iD: PrivacyQuestions.IDVerification,
      selector: "id-verification",
      isRequired: true
    },
    {
      iD: PrivacyQuestions.PrivacyConsent,
      selector: "privacy-consent",
      isRequired: true
    },
    {
      iD: PrivacyQuestions.CallRecordingConsent,
      selector: "call-recording-consent",
      isRequired: false
    },
    {
      iD: PrivacyQuestions.MarketingConsent,
      selector: "marketing-consent",
      isRequired: false
    },
    {
      iD: PrivacyQuestions.ElectronicConsent,
      selector: "electronic-consent",
      isRequired: false
    }
  ];

  getPrivacyQuestions(journeyID: string): Observable<PrivacyQuestion[]> {
    return this.http.get<PrivacyQuestion[]>(`/api/privacyquestions/${journeyID}/latest`);
  }

  savePrivacyQuestions(privacyQuestions: PrivacyQuestion[]): Observable<PrivacyQuestion[]> {
    return this.http.post<PrivacyQuestion[]>("/api/privacyquestions/", privacyQuestions);
  }

  async refresh(journeyID: string): Promise<void> {
    if (!this.householdService.household) return;

    const privacyQuestions: PrivacyQuestion[] = this.getRefreshPrivacyQuestions(journeyID, this.householdService.household.client);

    if (this.householdService.household.spouse) {
      privacyQuestions.push(...this.getRefreshPrivacyQuestions(journeyID, this.householdService.household.spouse));
    }

    await firstValueFrom(this.savePrivacyQuestions(privacyQuestions));
  }

  // eslint-disable-next-line class-methods-use-this
  createPrivacyQuestion(journeyID: string, questionID: number, answer: boolean, clientID: string): PrivacyQuestion {
    return {
      journeyID,
      questionID,
      created: moment().utc().toDate(),
      answer,
      lastModified: moment().utc().toDate(),
      journeyClientID: clientID
    };
  }

  async getPdfSection(journeyID: string): Promise<PdfSection> {
    const pdfSection: PdfSection = {
      breakLine: false,
      pdfSectionType: PdfSectionTypes.Table,
      title: "Privacy questions",
      content: {
        tableHeaders: [
          {
            name: "Question",
            width: "40%"
          },
          {
            name: this.journeyClientService.getNonNullablePrimary().firstName ?? "Primary",
            width: this.journeyClientService.spouseJourneyClient() ? "30%" : "60%"
          }
        ],
        tableRows: []
      }
    };

    if (this.journeyClientService.spouseJourneyClient()) {
      (pdfSection.content as TableContent).tableHeaders.push(
        {
          name: this.journeyClientService.spouseJourneyClient()?.firstName ?? "Spouse",
          width: "30%"
        }
      );
    }

    const answers = await firstValueFrom(this.getPrivacyQuestions(journeyID));

    for (const question of this.referenceQuestions) {
      (pdfSection.content as TableContent).tableRows.push(this.getTableRow(answers.filter(x => x.questionID === question.iD), question));
    }

    return pdfSection;
  }

  async refreshIDVerificationQuestion(journeyID: string): Promise<void> {
    if (!this.householdService.household) return;

    const privacyQuestions: PrivacyQuestion[] = [this.createPrivacyQuestion(journeyID, PrivacyQuestions.IDVerification, false, this.householdService.household.client.clientID)];

    if (this.householdService.household.spouse) {
      privacyQuestions.push(this.createPrivacyQuestion(journeyID, PrivacyQuestions.IDVerification, false, this.householdService.household.spouse.clientID));
    }

    await firstValueFrom(this.savePrivacyQuestions(privacyQuestions));
  }

  private getRefreshPrivacyQuestions(journeyID: string, client: Client) {
    const questions = [
      this.createPrivacyQuestion(journeyID, PrivacyQuestions.PrivacyConsent, (client.privacyConsent ?? false), client.clientID),
      this.createPrivacyQuestion(journeyID, PrivacyQuestions.ElectronicConsent, (client.electronicConsent ?? false), client.clientID),
      this.createPrivacyQuestion(journeyID, PrivacyQuestions.MarketingConsent, (client.marketingConsent ?? false), client.clientID),
    ];

    if (this.journeyService.getNonNullableJourney().sessionType !== SessionTypes.AdviserNotes.toString()) {
      questions.push(this.createPrivacyQuestion(journeyID, PrivacyQuestions.IDVerification, false, client.clientID));
    }

    return questions;
  }

  private getTableRow(answers: PrivacyQuestion[], question: Question): TableRow {
    const tableRow: TableRow = {
      columns: [
        {
          content: this.textTemplateService.getSectionElementText("privacy", question.selector)
        },
        {
          content: answers.find(x => x.journeyClientID === this.journeyClientService.getNonNullablePrimaryClientID())?.answer ? "Yes" : "No"
        }]
    }

    if (this.journeyClientService.spouseJourneyClient()) {
      tableRow.columns.push({
        content: answers.find(x => x.journeyClientID === this.journeyClientService.spouseJourneyClient()?.journeyClientID)?.answer ? "Yes" : "No"
      });
    }

    return tableRow;
  }
}

export interface Question {
  iD: number;
  selector: string;
  isRequired: boolean;
}